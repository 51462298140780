import { PropsWithChildren } from "react"
import { FallbackProps } from "react-error-boundary"
import { Text, Flex } from "prosapient-styleguide"
import styled from "styled-components"

const Container = styled(Flex)`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Shuttle = styled.svg`
  @keyframes CogAnimation {
    0% {
      transform: rotate(-360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  width: 5rem;
  height: 5rem;
  margin: 7rem;
  transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);
  animation: CogAnimation 30s linear infinite;
  fill: white;
`

const Astronaut = styled.svg`
  @keyframes CogAnimation2 {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  width: 10rem;
  height: 10rem;
  transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);
  animation: CogAnimation2 25s linear infinite;
  fill: white;
`

const World = styled.svg`
  width: 8rem;
  height: 8rem;
  transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);
  fill: white;
`

export const Error = ({ error }: PropsWithChildren<FallbackProps>) => {
  return (
    <Container bg="alpha.600">
      <Flex justifyContent="center" flexWrap="wrap">
        <World x="0px" y="0px" viewBox="0 0 512 512">
          <g>
            <g>
              <path
                d="M387.664,464.017c-2.77-4.774-8.887-6.4-13.667-3.63l-0.385,0.222c-4.776,2.771-6.401,8.89-3.631,13.667
			c1.855,3.197,5.21,4.983,8.658,4.983c1.702,0,3.43-0.437,5.009-1.353l0.385-0.222
			C388.809,474.913,390.434,468.794,387.664,464.017z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M477.694,128.08C443.512,68.875,388.321,26.526,322.287,8.832C256.255-8.862,187.283,0.219,128.078,34.4
			C68.874,68.581,26.524,123.773,8.831,189.806c-17.693,66.033-8.613,135.006,25.568,194.21
			c30.661,53.107,79.393,93.468,137.219,113.646c27.411,9.564,56.026,14.337,84.597,14.337c31.065,0,62.074-5.646,91.385-16.911
			c5.155-1.981,7.727-7.766,5.745-12.921s-7.768-7.73-12.921-5.745c-78.02,29.987-162.482,16.362-225.585-31.004l8.959-46.765
			l4.317-1.402c16.313-5.3,25.273-22.884,19.975-39.198l-3.168-9.749c-0.733-2.256-0.729-4.641,0.01-6.895
			c2.779-8.472,1.786-17.741-2.725-25.433c-4.511-7.691-12.116-13.084-20.867-14.795l-22.57-4.41l-43.548-33.11
			c-2.609-1.984-6.024-2.563-9.142-1.551l-25.496,8.282c-0.541-9.119-0.549-18.235-0.035-27.303
			c1.006,0.937,1.661,1.574,2.064,1.992c0.499,0.831,1.117,1.593,1.841,2.253c2.082,1.896,3.968,2.756,7.928,2.756
			c3.639-0.001,9.026-0.726,17.921-2.04c8.436-1.247,16.828-2.652,16.911-2.666c3.363-0.563,6.206-2.803,7.544-5.938
			c1.338-3.136,0.985-6.737-0.936-9.555l-12.261-17.984l17.562-12.558c1.17-0.837,2.146-1.916,2.861-3.164l27.223-47.525
			l10.973-11.758c11.213-12.018,14.413-29.601,8.151-44.797c-3.694-8.963-10.146-16.425-18.216-21.454
			c8.74-6.871,18.059-13.215,27.963-18.932c36.338-20.98,75.994-31.147,115.233-31.536l-17.856,22.399L169.24,65.795
			c-2.689,0.942-4.849,2.988-5.935,5.622l-18.492,44.828c-1.313,3.185-0.896,6.819,1.105,9.623l27.008,37.832
			c-3.868,3.529-7.633,8.669-12.13,15.051c-1.972,2.799-3.834,5.442-5.121,6.924c-1.119,1.29-2.239,2.55-3.349,3.798
			c-5.809,6.535-11.815,13.294-16.082,22.322c-9.382,19.859-7.487,42.941,4.945,60.239c12.021,16.725,31.839,25.227,52.991,22.739
			c3.446-0.404,6.659-1.199,9.768-1.969c8.573-2.121,11.289-2.333,14.039,0.241c1.338,1.253,1.461,1.49,1.434,5.739
			c-0.019,2.704-0.04,6.068,0.892,9.909c1.493,6.145,5.352,10.392,8.452,13.804c1.541,1.695,3.135,3.449,3.626,4.626
			c3.119,7.474,1.761,11.613-1.215,20.683c-0.255,0.776-0.515,1.568-0.776,2.38c-4.521,13.995,1.781,27.877,7.341,40.123
			c1.806,3.977,3.511,7.731,4.628,11.049c8.991,26.679,15.731,32.789,21.14,35.249c2.833,1.288,5.765,1.853,8.727,1.852
			c14.415-0.001,29.457-13.385,36.367-21.87c4.34-5.328,5.409-10.663,6.189-14.56c0.389-1.938,0.695-3.468,1.279-4.468
			c0.894-1.53,1.947-2.716,3.28-4.218c2.681-3.019,6.018-6.775,8.179-13.584c1.642-5.172,2.835-6.44,6.786-10.648
			c0.688-0.731,1.421-1.513,2.208-2.369c13.319-14.493,9.889-25.564,6.256-37.286c-3.057-9.863,2.29-16.171,15.218-28.21
			c5.604-5.219,11.398-10.615,15.877-17.099c1.96-2.838,7.923-11.471,4.233-20.028c-3.617-8.39-12.81-9.8-20.196-10.932
			c-2.917-0.448-7.325-1.124-8.728-2.041c-6.187-4.045-9.972-12.487-13.633-20.652c-0.729-1.625-1.448-3.229-2.177-4.786
			c-1.3-2.778-2.599-6.139-3.974-9.697c-3.638-9.416-7.761-20.089-14.78-27.529c-6.309-6.685-18.202-9.905-28.695-12.747
			c-3.529-0.955-6.86-1.857-9.057-2.668c-1.841-0.68-3.839-0.803-5.745-0.355c-5.107,1.197-8.293,2.516-10.659,4.412
			c-0.625,0.501-2.476,2.146-3.56,4.79c-3.661-1.757-8.695-5.112-11.793-7.177c-0.064-0.043-0.129-0.086-0.193-0.129
			c1.044-4.178,0.099-7.531-0.891-9.625c-5.586-11.816-24.129-10.891-27.787-10.58c-2.078,0.174-4.707,0.267-7.488,0.365
			c-4.368,0.155-9.196,0.329-13.973,0.865l0.246-0.686c3.626-10.103,13.274-16.891,24.008-16.891h5.685
			c5.521,0,9.999-4.478,9.999-9.999c0-5.521-4.478-9.999-9.999-9.999h-5.685c-15.936,0-30.529,8.387-38.708,21.588l-14.915-20.893
			l14.674-35.569L244.787,60.5c1.774-0.622,3.338-1.732,4.511-3.203l28.763-36.079c73.494,6.991,142.674,48.197,182.316,116.859
			c10.559,18.289,18.37,37.543,23.563,57.236l-4.239,3.78c-5.533,4.937-8.775,12.018-8.899,19.429
			c-0.001,0.081-0.003,0.161-0.006,0.241l-6.906-18.992c-1.029-2.834-2.545-5.433-4.503-7.724l-12.319-14.423
			c-4.776-5.591-11.73-8.796-19.081-8.796h-15.079c-5.353,0-10.245,2.932-12.77,7.649c-2.525,4.718-2.249,10.416,0.72,14.868
			l1.526,2.291c-9.744,8.379-21.014,15.084-33.014,19.606L348.7,173.649v-8.833c0-2.732-1.118-5.347-3.095-7.232l-18.613-17.769
			c-1.027-0.98-2.251-1.73-3.59-2.201l-16.755-5.888c-5.213-1.834-10.919,0.909-12.749,6.118
			c-1.831,5.211,0.908,10.918,6.118,12.749l14.724,5.175l13.962,13.327v7.008c0,1.611,0.39,3.199,1.136,4.628l25.877,49.567
			c2.327,4.46,7.609,6.498,12.323,4.754l7.535-2.778c17.505-6.456,33.73-16.841,46.922-30.031c3.37-3.371,3.894-8.65,1.249-12.617
			l-0.532-0.799h4.775c1.493,0,2.906,0.651,3.876,1.787l12.319,14.423c0.397,0.465,0.704,0.991,0.914,1.568l13.595,37.383
			c1.168,3.212,3.896,5.604,7.23,6.344c0.72,0.159,1.445,0.237,2.166,0.237c2.623,0,5.176-1.033,7.07-2.929l6.471-6.471
			c4.098-4.099,6.919-9.285,8.267-15.034c10.008,80.205-21.441,163.173-87.915,215.347c-4.345,3.409-5.103,9.694-1.692,14.039
			c3.409,4.345,9.693,5.103,14.039,1.692c47.992-37.667,80.962-91.542,92.837-151.701
			C519.15,244.773,508.685,181.758,477.694,128.08z M47.282,282.747l41.233,31.351c1.219,0.927,2.633,1.561,4.135,1.854
			l24.852,4.856c3.172,0.62,5.818,2.496,7.453,5.284c1.635,2.787,1.979,6.012,0.973,9.083c-2.07,6.313-2.08,12.99-0.027,19.307
			l3.168,9.748c1.893,5.826-1.309,12.107-7.134,14l-9.937,3.229c-3.467,1.126-6.045,4.05-6.73,7.628l-7.998,41.749
			c-17.583-16.093-33.021-35.113-45.552-56.818C36.43,347.541,26.878,319.3,22.668,290.744L47.282,282.747z M105.559,123.258
			l-11.763,12.603c-0.525,0.563-0.984,1.185-1.367,1.853L65.717,184.35l-23.741,16.976c-4.422,3.162-5.508,9.275-2.446,13.767
			l8.879,13.024c-5.118,0.762-9.711,1.39-12.765,1.723c-0.587-0.563-1.201-1.134-1.836-1.724c-2.377-2.208-5.68-5.157-10.604-9.466
			c8.121-50.263,32.341-97.615,70.433-133.76c7.294,2.297,13.28,7.738,16.204,14.835
			C113.131,107.707,111.449,116.945,105.559,123.258z M187.089,177.901c4.46-2.51,14.812-2.877,22.37-3.145
			c3.029-0.107,5.891-0.209,8.447-0.423c1.349-0.113,2.847-0.092,4.26,0.018c-0.631,1.62-0.836,3.398-0.562,5.162
			c0.481,3.103,2.394,5.798,5.162,7.279c1.876,1.004,4.487,2.745,7.251,4.588c7.815,5.21,16.674,11.115,24.996,11.645
			c7.568,0.491,11.779-3.822,13.583-5.659c0.144-0.146,0.28-0.292,0.424-0.425c1.016-0.828,1.968-1.938,2.676-3.277
			c1.599,0.457,3.279,0.912,5.001,1.379c5.921,1.603,16.942,4.587,19.377,7.167c4.325,4.585,7.696,13.312,10.671,21.011
			c1.503,3.891,2.923,7.563,4.516,10.968c0.684,1.461,1.357,2.966,2.041,4.49c4.614,10.292,9.844,21.958,20.939,29.21
			c5.031,3.289,11.198,4.234,16.64,5.069c0.658,0.101,1.412,0.217,2.178,0.343c-3.23,4.506-7.829,8.788-12.688,13.313
			c-12.379,11.528-27.785,25.876-20.69,48.765c3.349,10.808,3.505,11.976-1.88,17.834c-0.734,0.799-1.418,1.527-2.059,2.209
			c-4.612,4.91-8.255,8.788-11.271,18.288c-0.876,2.759-2.011,4.037-4.072,6.357c-1.648,1.856-3.701,4.168-5.594,7.408
			c-2.268,3.882-3.018,7.62-3.619,10.623c-0.593,2.955-0.929,4.438-2.089,5.862c-2.641,3.242-7.567,7.95-12.624,11.163
			c-4.7,2.984-7.444,3.392-8.379,3.336c-1.067-1.144-4.932-6.153-10.772-23.486c-1.44-4.276-3.438-8.676-5.37-12.931
			c-4.259-9.38-8.662-19.08-6.52-25.709c0.253-0.782,0.503-1.546,0.749-2.293c3.422-10.43,6.652-20.279,0.668-34.619
			c-1.822-4.367-4.723-7.558-7.28-10.374c-1.655-1.821-3.53-3.885-3.819-5.073c-0.352-1.449-0.341-3.122-0.328-5.059
			c0.034-5.225,0.086-13.12-7.763-20.47c-11.066-10.358-23.97-7.167-32.51-5.055c-2.623,0.648-5.101,1.262-7.297,1.52
			c-14.048,1.646-26.59-3.654-34.42-14.55c-8.191-11.397-9.38-26.734-3.102-40.024c3.039-6.431,7.63-11.598,12.946-17.578
			c1.161-1.307,2.333-2.625,3.505-3.975c1.95-2.248,4.096-5.292,6.365-8.515C179.855,186.423,184.899,179.263,187.089,177.901z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M410.022,344.278l-4.811-18.134c-1.051-3.96-4.411-6.878-8.478-7.364c-4.072-0.476-8.021,1.56-9.976,5.161
			c-1.096,2.02-2.083,4.16-3.037,6.229c-1.605,3.481-3.265,7.081-4.992,9.068c-0.97,1.116-3.462,2.319-5.87,3.481
			c-5.757,2.778-13.642,6.583-16.654,16.185c-1.87,5.956-1.172,10.668-0.662,14.108c0.441,2.983,0.543,4.017-0.256,5.893l-0.1,0.233
			c-3.183,7.469-9.105,21.374-0.801,33.195c5.925,8.436,12.155,10.605,16.996,10.605c0.945,0.003,1.839-0.08,2.666-0.215
			c10.2-1.667,17.868-12.277,24.134-33.393l11.762-39.641C410.466,347.926,410.494,346.054,410.022,344.278z M379.01,383.641
			c-3.194,10.766-6.02,15.792-7.651,18.02c-0.188-0.24-0.392-0.512-0.61-0.823c-1.911-2.722,0.945-9.426,2.835-13.863l0.101-0.234
			c3.048-7.154,2.233-12.648,1.64-16.66c-0.381-2.569-0.517-3.673-0.04-5.19c0.392-1.247,2.735-2.457,6.265-4.161
			c1.565-0.756,3.288-1.587,5.027-2.593L379.01,383.641z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M270.03,118.86l-0.675-0.236c-5.207-1.824-10.917,0.915-12.745,6.126c-1.828,5.212,0.915,10.917,6.126,12.745l0.674,0.236
			c1.095,0.384,2.211,0.566,3.31,0.566c4.128,0,7.992-2.577,9.436-6.692C277.984,126.393,275.241,120.687,270.03,118.86z"
              />
            </g>
          </g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </World>

        <Shuttle version="1.1" x="0px" y="0px" viewBox="0 0 511.993 511.993">
          <g>
            <g>
              <g>
                <path
                  d="M462.025,146.637l17.85-17.85c0.158-0.158,0.308-0.321,0.45-0.483c0.425-0.4,0.85-0.812,1.312-1.275
				c29.325-29.325,31.417-77.013,30.05-102.892c-0.659-12.906-10.992-23.212-23.9-23.837c-25.821-1.338-73.496,0.725-102.904,30.129
				c-0.412,0.421-0.808,0.821-1.192,1.237c-0.167,0.142-0.329,0.292-0.488,0.45l-17.85,17.85l-24.183-24.183
				c-10.015-9.992-26.227-9.992-36.242,0L203.65,127.062c-40.128-5.734-79.237-17.143-116.154-33.883
				c-12.971-5.816-28.185-3.034-38.258,6.996l-41.7,41.7c-10.027,10.013-10.053,26.255-0.058,36.3L110.225,280.92l-12.158,12.158
				c-3.671,3.693-5.45,8.863-4.829,14.033l-30.846,15.471c-4.914,2.459-8.348,7.128-9.232,12.551
				c-0.884,5.423,0.89,10.941,4.769,14.833l52.05,52.046l52.046,52.05c3.892,3.879,9.409,5.653,14.833,4.769
				c5.423-0.884,10.092-4.318,12.551-9.232l15.471-30.846c0.636,0.097,1.278,0.158,1.921,0.184
				c4.544,0.007,8.902-1.797,12.112-5.013l12.158-12.158l102.741,102.746c4.792,4.811,11.31,7.504,18.1,7.479h0.046
				c6.816,0.009,13.352-2.705,18.158-7.537l41.7-41.7c10.02-10.062,12.809-25.256,7.017-38.221
				c-16.752-36.928-28.167-76.05-33.904-116.192l101.279-101.279c9.992-10.015,9.992-26.227,0-36.242L462.025,146.637z
				 M486.821,17.337L486.821,17.337c4.231,0.162,7.634,3.537,7.829,7.767c0.835,14.414-0.047,28.876-2.626,43.082l-48.217-48.217
				C457.99,17.395,472.429,16.511,486.821,17.337z M394.433,45.02c0.534-0.403,1.02-0.867,1.446-1.383
				c0.358-0.433,0.721-0.783,1.15-1.217c7.944-7.677,17.447-13.554,27.865-17.231L486.8,87.095
				c-3.686,10.433-9.575,19.95-17.267,27.904c-0.396,0.392-0.746,0.754-1.179,1.112c-0.502,0.413-0.954,0.885-1.346,1.404
				l-193.4,193.404l-12.106-12.106l158.231-158.227c6.658-6.675,6.658-17.479,0-24.154l-24.175-24.175
				c-6.673-6.662-17.481-6.662-24.154,0L213.177,250.489l-12.106-12.106L394.433,45.02z M377.46,158.726l-36.28,36.279
				l-24.188-24.188l36.281-36.277L377.46,158.726z M365.341,122.473l18.151-18.149l24.175,24.196l-18.139,18.14L365.341,122.473z
				 M329.114,207.071l-36.28,36.279l-24.19-24.19l36.281-36.277L329.114,207.071z M280.767,255.416l-30.238,30.237l-24.192-24.192
				l30.239-30.236L280.767,255.416z M316.996,37.849c3.346-3.339,8.763-3.339,12.108,0l24.183,24.183l-24.194,24.193l-12.097-12.097
				c-3.332-3.332-8.735-3.332-12.067,0c-3.332,3.332-3.332,8.735,0,12.067l12.097,12.097l-36.28,36.279l-12.097-12.097
				c-3.332-3.332-8.735-3.332-12.067,0c-3.332,3.332-3.332,8.735,0,12.067l12.097,12.097l-36.281,36.28l-12.095-12.098
				c-3.332-3.332-8.735-3.332-12.067,0c-3.332,3.332-3.332,8.735,0,12.067l12.095,12.097l-30.236,30.236l-24.183-24.183
				c-3.337-3.345-3.337-8.76,0-12.104L316.996,37.849z M61.304,112.241c5.054-5.028,12.684-6.42,19.188-3.5
				c34.512,15.604,70.852,26.797,108.162,33.317l-34.807,34.807c-9.992,10.013-9.992,26.224,0,36.237l16.078,16.079
				c-6.532,1.342-12.528,4.57-17.245,9.284l-0.035,0.035l-108.8-108.8L61.304,112.241z M19.546,166.103
				c-1.603-1.6-2.497-3.777-2.479-6.042c-0.003-2.297,0.91-4.5,2.537-6.121l12.173-12.173l30.236,30.236L43.73,190.289
				L19.546,166.103z M55.797,202.356l18.283-18.285l24.195,24.195L79.991,226.55L55.797,202.356z M92.057,238.617l18.285-18.285
				l30.236,30.235l-18.286,18.285L92.057,238.617z M164.746,250.533c3.235-3.239,7.626-5.058,12.204-5.054
				c4.523-0.011,8.864,1.781,12.063,4.979l30.225,30.229l-36.475,36.475l-24.183-24.183c-3.334-3.322-8.728-3.318-12.056,0.01
				c-3.328,3.328-3.333,8.722-0.01,12.056l24.183,24.183l-18.275,18.275l-42.313-42.312L164.746,250.533z M70.042,337.841
				l32.354-16.229l37.958,37.958l-24.337,24.333L70.042,337.841z M174.092,441.995l-46.012-46.017l24.342-24.342l37.954,37.954
				L174.092,441.995z M206.8,401.883l-42.313-42.313l18.275-18.275l24.183,24.184c2.154,2.162,5.299,3.008,8.247,2.22
				c2.948-0.788,5.251-3.091,6.039-6.039c0.788-2.948-0.058-6.093-2.22-8.247l-24.183-24.183l36.475-36.475l30.229,30.225
				c6.667,6.727,6.633,17.581-0.075,24.267L206.8,401.883z M243.138,389.7l18.285-18.286l30.235,30.235l-18.285,18.285
				L243.138,389.7z M285.44,432.001l18.285-18.285l24.195,24.195l-18.286,18.284L285.44,432.001z M358.05,492.387
				c-1.621,1.627-3.824,2.541-6.121,2.537h-0.017c-2.26,0.012-4.431-0.881-6.029-2.479L321.7,468.262l18.286-18.284l30.236,30.236
				L358.05,492.387z M403.271,431.537L403.271,431.537c2.902,6.497,1.502,14.11-3.521,19.15l-17.46,17.46l-108.8-108.8l0.035-0.035
				c4.714-4.717,7.942-10.713,9.284-17.245l16.079,16.079c10.012,9.994,26.226,9.994,36.237,0l34.807-34.807
				C376.456,360.661,387.657,397.014,403.271,431.537z M474.142,194.995L323.058,346.078c-3.348,3.329-8.756,3.329-12.104,0
				l-24.183-24.183l30.236-30.236l12.097,12.095c3.332,3.332,8.735,3.332,12.067,0c3.332-3.332,3.332-8.735,0-12.067l-12.098-12.095
				l36.28-36.282l12.097,12.097c3.332,3.332,8.734,3.332,12.067,0c3.332-3.332,3.332-8.734,0-12.067l-12.097-12.096l36.279-36.28
				l12.098,12.097c3.334,3.32,8.727,3.315,12.054-0.013c3.327-3.327,3.333-8.72,0.012-12.054l-12.097-12.097l24.193-24.194
				l24.184,24.184C477.479,186.233,477.479,191.649,474.142,194.995z"
                />
                <path
                  d="M413.375,74.424c2.5-2.475,10.808-1.396,18.2,5.992c7.383,7.383,8.479,15.712,5.992,18.2
				c-3.332,3.332-3.332,8.735,0,12.067s8.735,3.332,12.067,0c10.192-10.188,7.558-28.783-5.992-42.333
				c-13.55-13.546-32.142-16.175-42.333-5.992c-3.332,3.332-3.332,8.735,0,12.067C404.64,77.756,410.043,77.756,413.375,74.424z"
                />
              </g>
            </g>
          </g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </Shuttle>

        <Astronaut version="1.1" x="0px" y="0px" viewBox="0 0 459.793 459.793">
          <g>
            <path
              d="M446.198,320.375l-77.179-77.179l16.624-16.624c6.708-6.709,6.708-17.625,0-24.333l-62.22-62.221l10.632-1.85
		c16.236-3.129,26.91-18.843,23.791-35.045c-0.004-0.02-0.008-0.04-0.013-0.062c-0.001-0.007-0.003-0.014-0.004-0.021
		l-13.443-69.183c-1.516-7.87-5.984-14.677-12.582-19.168c-6.61-4.5-14.583-6.144-22.446-4.628
		c-16.289,3.138-26.997,18.901-23.869,35.147l7.719,39.722l-21.139,3.734l-49.036-49.036c-5.718-5.717-14.483-6.543-21.104-2.516
		c-1.004-1.084-2.026-2.159-3.079-3.212C176.988,12.04,147.922,0,117.005,0C86.088,0,57.022,12.04,35.16,33.901
		C13.299,55.762,1.26,84.829,1.26,115.746c0,30.917,12.04,59.983,33.901,81.845c1.1,1.1,2.223,2.169,3.359,3.218
		c-3.601,6.588-2.533,14.972,2.914,20.419l47.555,47.555l-4.37,24.737l-39.729-7.72c-7.864-1.514-15.858,0.143-22.504,4.667
		c-6.633,4.516-11.12,11.335-12.635,19.202c-1.515,7.864,0.129,15.836,4.628,22.447c4.491,6.598,11.298,11.066,19.16,12.58
		l69.19,13.445c0.013,0.003,0.027,0.005,0.04,0.008c0.021,0.004,0.04,0.008,0.059,0.012c1.896,0.365,3.783,0.541,5.647,0.541
		c14.045-0.001,26.619-10,29.381-24.335c0.008-0.041,0.015-0.082,0.022-0.124l2.314-14.257l63.852,63.851
		c3.249,3.25,7.57,5.04,12.166,5.04c4.596,0,8.917-1.79,12.166-5.039l13.559-13.559l77.179,77.179
		c8.224,8.224,19.026,12.335,29.829,12.335c10.803-0.001,21.608-4.113,29.832-12.337c16.447-16.447,16.447-43.21,0-59.659
		l-64.541-64.54l7.762-7.763l64.54,64.54c16.448,16.448,43.212,16.448,59.66-0.001C462.646,363.586,462.646,336.823,446.198,320.375
		z M379.632,208.25c3.395,3.394,3.395,8.917,0,12.312l-16.624,16.623l-88.631-88.631l38.806-6.753L379.632,208.25z M210.867,43.09
		c2.325,0,4.512,0.905,6.156,2.55l44.83,44.83l-30.856,5.45c-3.217-18.653-11.016-36.659-23.387-52.199
		C208.631,43.309,209.732,43.09,210.867,43.09z M192.839,191.58c-20.256,20.256-47.188,31.412-75.834,31.412
		c-3.972,0-7.909-0.222-11.802-0.646l19.087-36.411c2.202,0.397,4.473,0.598,6.791,0.598c12.546,0,26.411-5.773,37.426-16.788
		c13.001-13.001,18.698-29.968,16.222-44.056l38.903-20.948C226.804,135.745,216.543,167.875,192.839,191.58z M48.157,83
		c1.887-0.636,3.019-2.469,2.865-4.368l0.012-0.014c-0.595-7.093,0.734-11.558,4.587-15.412c1.298-1.299,4.249-3.523,5.957-4.492
		c0.04-0.022,0.081-0.046,0.12-0.07c0.226-0.138,22.901-13.834,51.29-18.663c35.924-6.108,64.306,4.106,84.423,30.364
		c-5.577,5.199-17.795,17.327-25.758,30.672c-1.284-5.634-3.4-11.129-6.362-16.303c4.244-2.96,8.309-6.208,12.103-9.808
		c1.703-1.616,1.773-4.306,0.158-6.008c-1.615-1.703-4.306-1.772-6.008-0.158c-42.116,39.962-122.075,35.817-122.881,35.772
		c-2.33-0.135-4.353,1.652-4.489,3.996c-0.136,2.343,1.651,4.353,3.994,4.49c0.452,0.026,2.259,0.123,5.151,0.173
		c-0.362,16.33,5.888,32.063,17.53,43.705c7.99,7.99,17.841,13.433,28.572,15.954c-11.784,7.114-22.288,17.557-26.906,22.457
		c-41.569-27.67-36.346-94.82-36.287-95.508c0.01-0.111,0.015-0.223,0.016-0.334c0.036-4.622,1.866-8.978,5.154-12.265
		C43.296,85.279,45.571,83.873,48.157,83z M113.243,165.938c-13.745,0-26.667-5.353-36.386-15.072
		c-10.04-10.04-15.411-23.626-15.043-37.716c21.252-0.437,63.075-4.043,96.327-23.816c11.141,19.871,7.819,45.201-8.512,61.532
		C139.91,160.585,126.988,165.938,113.243,165.938z M118.57,174.194c14.004-1.226,27.011-7.258,37.07-17.317
		c10.001-10.001,15.815-22.9,17.198-36.251c0.023-0.101,0.059-0.197,0.074-0.302c0.081-0.53,0.189-1.067,0.306-1.605
		c7.49,11.94,3.276,31.019-10.721,45.015c-14.038,14.039-33.186,18.238-45.121,10.657
		C117.777,174.316,118.176,174.246,118.57,174.194z M9.76,115.746c0-28.646,11.155-55.578,31.412-75.834
		C61.427,19.655,88.359,8.5,117.005,8.5c28.646,0,55.578,11.155,75.834,31.412c15.854,15.853,25.69,35.476,29.523,56.011
		c-0.323,0.084-0.642,0.195-0.949,0.36l-39.268,21.144c-1.366-2.934-3.184-5.633-5.465-8.017
		c8.784-17.409,28.969-35.069,29.215-35.283c1.652-1.434,1.947-3.888,0.681-5.671c-22.014-31.013-54.953-43.739-95.261-36.811
		c-29.487,5.07-52.372,18.727-53.992,19.712c-2.126,1.217-5.706,3.833-7.713,5.84c-6.154,6.154-7.323,13.084-7.21,18.996
		c-2.603,1.254-4.955,2.917-7.015,4.977c-4.837,4.836-7.547,11.239-7.642,18.045c-0.162,2.039-1.402,19.862,2.858,41.165
		c5.954,29.773,19.957,51.921,40.494,64.049c0.674,0.398,1.419,0.59,2.159,0.59c1.194,0,2.371-0.502,3.203-1.454
		c5.11-5.849,18.621-19.249,31.556-25.796c2.387,2.319,5.099,4.167,8.048,5.557l-19.752,37.678
		c-20.76-4.037-39.851-14.137-55.138-29.424C20.915,171.324,9.76,144.392,9.76,115.746z M47.445,215.217
		c-2.306-2.306-3.067-5.653-2.188-8.636c14.835,11.765,32.148,19.606,50.657,22.996l-5.121,28.988L47.445,215.217z M222.366,377.828
		c-1.644,1.644-3.83,2.549-6.155,2.549c-2.326,0-4.512-0.905-6.156-2.55l-68.184-68.184l5.506-33.925l88.548,88.549L222.366,377.828
		z M372.765,441.445c-13.134,13.135-34.506,13.136-47.64,0.002l-3.251-3.251l24.904-27.668c1.611-1.706,1.534-4.396-0.173-6.008
		c-1.707-1.611-4.396-1.534-6.008,0.173l-24.74,27.486l-5.693-5.693c0.175-0.128,0.35-0.257,0.508-0.415l47.178-47.178
		l14.915,14.915C385.899,406.942,385.899,428.313,372.765,441.445z M298.944,92.549c1.123-0.198,2.118-0.839,2.764-1.778
		c0.645-0.939,0.886-2.099,0.669-3.217l-8.542-43.959c-2.241-11.636,5.444-22.935,17.131-25.186
		c5.626-1.083,11.329,0.091,16.056,3.308c4.727,3.218,7.93,8.101,9.019,13.755l1.569,8.077l-32.75,3.639
		c-2.333,0.259-4.014,2.361-3.755,4.693c0.242,2.174,2.082,3.781,4.219,3.781c0.157,0,0.315-0.009,0.475-0.026l33.439-3.715
		l10.21,52.544c0.011,0.08,0.025,0.159,0.042,0.239c0.01,0.048,0.022,0.104,0.036,0.16c2.155,11.542-5.483,22.722-17.003,24.943
		l-67.667,11.775c-2.313,0.402-3.861,2.603-3.458,4.915c0.156,0.897,0.593,1.668,1.192,2.266l-0.011,0.011l81.967,81.967
		l-39.883,39.883c-1.66,1.66-1.66,4.351,0,6.011c0.83,0.83,1.918,1.245,3.005,1.245c1.088,0,2.175-0.415,3.005-1.245l39.883-39.883
		l26.049,26.05l-22.367,23.692c-1.611,1.707-1.534,4.397,0.173,6.008c0.821,0.775,1.87,1.16,2.917,1.16
		c1.129,0,2.255-0.447,3.091-1.333l22.199-23.514l38.875,38.875c-0.121,0.097-0.248,0.182-0.36,0.293l-47.312,47.312l-48.818-48.817
		c-0.797-0.797-1.878-1.245-3.005-1.245c-1.127,0-2.208,0.448-3.005,1.245l-13.772,13.773c-1.66,1.66-1.66,4.351,0,6.011
		l46.62,46.62l-47.178,47.178c-0.158,0.158-0.287,0.333-0.415,0.508l-79.008-79.008l33.425-33.425c1.66-1.66,1.66-4.351,0-6.011
		c-1.66-1.66-4.351-1.66-6.011,0l-33.425,33.424l-71.711-71.711l-0.007,0.007c-0.609-0.61-1.401-1.048-2.317-1.197
		c-2.312-0.374-4.5,1.197-4.876,3.515l-10.818,66.654c-2.256,11.542-13.387,19.144-24.944,17.02
		c-0.034-0.008-0.066-0.016-0.095-0.022c-0.104-0.023-0.208-0.042-0.313-0.057l-55.405-10.766l2.815-28.156
		c0.233-2.335-1.471-4.418-3.806-4.652c-2.346-0.232-4.418,1.471-4.652,3.806l-2.737,27.374l-5.209-1.012
		c-5.648-1.087-10.53-4.29-13.748-9.017c-3.217-4.727-4.392-10.429-3.308-16.056c1.087-5.646,4.309-10.542,9.071-13.784
		c4.763-3.242,10.485-4.431,16.107-3.349l43.966,8.543c1.12,0.217,2.278-0.023,3.217-0.669c0.939-0.646,1.58-1.641,1.778-2.764
		l12.091-68.455c4.182,0.453,8.411,0.691,12.677,0.691c30.916,0,59.983-12.04,81.845-33.901
		c25.46-25.461,36.554-59.929,33.286-93.241L298.944,92.549z M440.187,374.024c-13.134,13.134-34.505,13.135-47.639,0
		l-12.717-12.717l47.312-47.312c0.112-0.112,0.197-0.239,0.294-0.36l12.75,12.75C453.321,339.52,453.321,360.89,440.187,374.024z"
            />
            <path
              d="M260.062,192.313c-3.151-3.15-7.34-4.886-11.796-4.886c-4.456,0-8.645,1.735-11.795,4.886l-45.416,45.416
		c-3.151,3.151-4.886,7.34-4.886,11.796c0,4.456,1.735,8.645,4.886,11.795l19.778,19.778c3.151,3.15,7.34,4.886,11.795,4.886
		s8.645-1.735,11.796-4.886l45.416-45.417c3.151-3.15,4.886-7.34,4.886-11.795c0-4.456-1.735-8.645-4.886-11.795L260.062,192.313z
		 M273.829,229.672l-45.416,45.417c-1.545,1.546-3.6,2.397-5.786,2.397c-2.185,0-4.24-0.851-5.785-2.397l-19.778-19.778
		c-1.545-1.545-2.396-3.6-2.396-5.785c0-2.186,0.851-4.24,2.396-5.785l45.416-45.416c1.545-1.545,3.6-2.397,5.785-2.397
		c2.186,0,4.24,0.851,5.785,2.397l3.518,3.518l-22.331,23.653c-1.611,1.706-1.534,4.396,0.173,6.008
		c0.821,0.775,1.87,1.16,2.917,1.16c1.129,0,2.255-0.447,3.091-1.333l22.163-23.475l10.248,10.248
		c1.545,1.545,2.396,3.6,2.396,5.785C276.225,226.072,275.374,228.127,273.829,229.672z"
            />
          </g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </Astronaut>
      </Flex>

      <Text color="white" fontSize={9} fontWeight={3} textAlign="center">
        Houston, we have a problem.
      </Text>
    </Container>
  )
}
