import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { prosapientTheme } from "prosapient-styleguide"
import { ThemeProvider } from "styled-components"
import { load } from "fontawesome-loader"
import { GlobalStyles } from "./GlobalStyles"

if (process.env.REACT_APP_MOCK_SERVER === "on") {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require("./mocks/browser")

  worker.start()
}

load()

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={prosapientTheme}>
      <GlobalStyles />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
