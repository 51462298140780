import { StatusType } from "services/types"

export const getStatusColor = (status: StatusType) => {
  switch (status) {
    case "ok":
      return "gamma.400"
    case "failed":
      return "delta.500"
    case "degradation":
      return "epsilon.400"
  }
}
