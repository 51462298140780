import { IGlobalStatus } from "services/types"
import { LazyDataOrModifiedFn } from "use-async-resource"
import { CardGrid } from "./CardGrid"

export const Systems = ({ reader }: { reader: LazyDataOrModifiedFn<IGlobalStatus> }) => {
  const result = reader()

  if (!result) {
    return null
  }

  return <CardGrid systems={result.groups} />
}
