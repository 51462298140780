import styled from "styled-components"
import { Text, Flex, FIcon, Tag, Grid } from "prosapient-styleguide"
import { IService } from "services/types"
import { getStatusColor } from "components/shared/utils"
import { capitalizeFirstLetter } from "shared/utils"

const ServiceTags = ({ tags }: { tags: string[] }) => {
  return (
    <Flex flexWrap="wrap" mt={4}>
      {tags.map((tag, idx) => (
        <Tag key={idx} sizing="sm" bg="alpha.100" color="alpha.400" mr={2} mb={2}>
          {tag}
        </Tag>
      ))}
    </Flex>
  )
}

const Wrapper = styled(Grid)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`

export const ServicesTable = ({ services }: { services: IService[] }) => {
  if (services.length === 0) return null
  return (
    <Wrapper mt={9} bg="alpha.50" borderRadius={4} p={6} pb={0}>
      {services.map((service, idx) => (
        <Flex alignItems="flex-start" key={idx} pr={8} pb={6}>
          <FIcon color={getStatusColor(service.status)} icon="circle" />
          <Flex alignItems="flex-start" flexDirection="column" ml={3}>
            <Flex alignItems="center">
              <Text textAlign="left">{capitalizeFirstLetter(service.name)}</Text>
            </Flex>
            {service.error && (
              <Text fontSize={5} mt={4} color="delta.600">
                {service.error}
              </Text>
            )}
            {service.tags.length > 0 && <ServiceTags tags={service.tags} />}
          </Flex>
        </Flex>
      ))}
    </Wrapper>
  )
}
