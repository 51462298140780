import { library } from "@fortawesome/fontawesome-svg-core"
import { faCircle } from "@fortawesome/free-solid-svg-icons"

/**
 *
 * If you are adding a new <FIcon /> instead of replacing <Icon /> with <FIcon /> you can skip first 2 steps.
 *
 * 1. Open ICONS-MIGRATION.md and find an old icon by name.
 * 2. If no icon found in ICONS-MIGRATION.md insert a new row with the old icon name and pick a resembling icon in https://fontawesome.com/icons?d=gallery
 * 3. Import the new icon in this file and add to a library as an argument.
 * 4. Add new icon or replace the old one with a fontawesome name.
 *
 */

export const load = () => {
  library.add(faCircle)
}
