import { Flex, Box, Logo, Text } from "prosapient-styleguide"
import { Container } from "components/Container"

export const Header = () => {
  return (
    <Box bg="alpha.600" py={5}>
      <Container px={8}>
        <Flex alignItems="center">
          <Logo height={32} vendor="prosapient" color="white" type="icon-only" />
          <Text color="white" fontSize={7} ml={4}>
            proSapient Status
          </Text>
        </Flex>
      </Container>
    </Box>
  )
}
