import { Suspense, useEffect } from "react"
import { useAsyncResource } from "use-async-resource"
import { fetchServices } from "./services"
import { Systems } from "components/Systems"
import { Header } from "components/Header"
import { IGlobalStatus } from "services/types"
import { Loading } from "components/Loading"
import { Container } from "components/Container"
import { ErrorBoundary } from "react-error-boundary"
import { Error } from "components/Error"

export default function App() {
  const [reader, getServices] = useAsyncResource<IGlobalStatus, []>(fetchServices)

  useEffect(() => {
    getServices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ErrorBoundary FallbackComponent={Error}>
      <Header />
      <Container py={[8, 10]} px={[8, 10]}>
        <Suspense fallback={<Loading />}>
          <Systems reader={reader} />
        </Suspense>
      </Container>
    </ErrorBoundary>
  )
}
