import styled from "styled-components"
import { motion } from "framer-motion"

const Wrapper = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #642db4;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .circle {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: #fff;
    margin: 0 4px;
    border-radius: 50%;
    animation: loading 1.5s cubic-bezier(0.8, 0.5, 0.2, 1.4) infinite;
    transform-origin: bottom center;
    position: relative;
  }
  @keyframes loading {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(50px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  .circle-1 {
    animation-delay: 0.1s;
  }
  .circle-2 {
    animation-delay: 0.2s;
  }
  .circle-3 {
    animation-delay: 0.3s;
  }
  .circle-4 {
    animation-delay: 0.4s;
  }
`

export const Loading = () => {
  return (
    <Wrapper
      initial={{
        y: "-100%",
        borderBottomLeftRadius: "100%",
        borderBottomRightRadius: "100%",
      }}
      animate={{
        y: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: { duration: 0.4 },
      }}
      exit={{
        y: "-100%",
        borderBottomLeftRadius: "90%",
        borderBottomRightRadius: "90%",
      }}
    >
      <span className="circle circle-1"></span>
      <span className="circle circle-2"></span>
      <span className="circle circle-3"></span>
      <span className="circle circle-4"></span>
    </Wrapper>
  )
}
