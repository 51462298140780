import { PropsWithChildren } from "react"
import { Box, Text, Flex } from "prosapient-styleguide"
import Tooltip from "react-tooltip-lite"
import styled from "styled-components"
import { parseISO, format } from "date-fns"
import { StatusType, IHistoryEntity } from "services/types"
import { getStatusColor } from "components/shared/utils"

const getStatusText = (status: StatusType, error?: string) => {
  switch (status) {
    case "ok":
      return undefined
    case "failed":
    case "degradation":
      return error
  }
}

const TimelineItemTooltip = ({ content, children }: PropsWithChildren<{ content: React.ReactNode }>) => {
  const wrapperStyles = {
    flex: 1,
  }

  return (
    <Tooltip
      tipContentHover
      hoverDelay={0}
      arrowSize={7}
      distance={15}
      direction="down"
      background="lavender"
      color="white"
      padding={15}
      styles={wrapperStyles}
      content={content}
    >
      {children}
    </Tooltip>
  )
}

const TimelineItem = ({ date, commitSha, status, error }: IHistoryEntity) => {
  const formattedDate = format(parseISO(date), "yyyy-MM-dd HH:mm")
  const statusText = getStatusText(status, error)

  return (
    <TimelineItemTooltip
      content={
        <Box>
          <Text mb={1}>{formattedDate}</Text>
          <Text mb={1}>Last commit: {commitSha}</Text>
          {statusText && <Text mb={1}>{statusText}</Text>}
        </Box>
      }
    >
      <Flex borderRadius={2} cursor="pointer" bg={getStatusColor(status)} height={30} flex="1 1 0%" />
    </TimelineItemTooltip>
  )
}

const TimelineRowContainer = styled(Flex)<{ gap: number }>`
  gap: ${props => props.gap}px;

  @media screen and (max-width: 568px) {
    gap: 1px;
  }
`

const TimelineRow = ({ history }: { history: IHistoryEntity[] }) => {
  return (
    <TimelineRowContainer gap={4} flex={1} mt={3} mb={3}>
      {history.map((item, idx) => (
        <TimelineItem key={idx} {...item} />
      ))}
    </TimelineRowContainer>
  )
}

const TimelinePeriod = ({ history }: { history: IHistoryEntity[] }) => {
  const historyLengthInDays = history.length
  const numberOfUptimeDays = history.filter(item => item.status === "ok").length
  const uptimePercentage = ((numberOfUptimeDays / historyLengthInDays) * 100).toFixed(2)
  const formattedFirstDate = format(parseISO(history[0].date), "PPP")

  return (
    <Flex alignItems="center" mt={4}>
      <Text color="beta.600" fontSize={[4, 5]}>
        {formattedFirstDate}
      </Text>
      <Flex flex={1} alignItems="center" justifyContent="center">
        <Box flex="1" bg="beta.400" height={1} mx={3} />
        <Text color="beta.500" fontSize={[4, 5]} textAlign="center" style={{ whiteSpace: "nowrap" }}>
          Uptime {uptimePercentage}%
        </Text>
        <Box flex="1" bg="beta.400" height={1} mx={3} />
      </Flex>
      <Text color="beta.600" fontSize={[4, 5]}>
        Now
      </Text>
    </Flex>
  )
}

export const History = ({ history }: { history: IHistoryEntity[] }) => {
  return (
    <Box width="100%">
      <TimelineRow history={history} />
      <TimelinePeriod history={history} />
    </Box>
  )
}
