import { useRef, useEffect, useState, memo } from "react"
import { Flex, Box, Grid, Text, Tag, Icon } from "prosapient-styleguide"
import styled, { css } from "styled-components"
import { ISystem, IEnvironment, StatusType } from "services/types"
import { Environment } from "components/Environment/index"
import { capitalizeFirstLetter } from "shared/utils"
import { getStatusColor } from "./shared/utils"

const StyledExpander = styled(Flex)<{ ml: number; width: number }>`
  transition: all 0.2s ease-in-out;

  background-color: ${props => props.theme.colors.white};
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  box-shadow: rgb(0 0 0 / 13%) 0px 3px 18px;
  border-radius: ${props => props.theme.newSpace[2]};
  margin-left: ${props => props.ml}px;
  width: ${props => props.width}px;
`

const StyledInner = styled(Flex)`
  padding: 16px 20px;
  position: relative;
  cursor: pointer;
  z-index: 1;

  box-shadow: rgb(0 0 0 / 13%) 0px 3px 18px;
  border-radius: ${props => props.theme.newSpace[2]};
  text-align: center;

  transition: all 0.2s ease-in-out;

  &:after {
    bottom: -20px;
    cursor: default;
    content: "▲";
    font-size: ${props => props.theme.newFontSizes[8]};
    color: ${props => props.theme.colors.white};
    transform: scaleX(2);
    display: block;
    position: absolute;
    transition: all 0.2s ease-in-out;
    left: calc(50% - 15px);
    opacity: 0;
    color: ${props => props.theme.colors.white};
  }
`

const StyledCard = styled(Box)<{ expanded: boolean }>`
  transition: all 0.2s ease-in-out;

  &:hover {
    ${StyledInner} {
      background-color: $turquoise;
      transform: scale(1.025);
    }
  }

  ${props =>
    props.expanded
      ? css`
          ${StyledInner} {
            &:after {
              bottom: -28px;
              text-shadow: rgba(0, 0, 0, 0.04) 1px -2px 2px;
              opacity: 1;
            }
          }

          ${StyledExpander} {
            min-height: 200px;
            overflow: visible;
            margin-top: 20px;
            opacity: 1;
          }
        `
      : css`
          ${StyledInner} {
            &:after {
              content: "";
              opacity: 0;
            }
          }
          ${StyledExpander} {
            max-height: 0;
            min-height: 0;
            overflow: hidden;
            margin-top: 0;
            opacity: 0;
          }
        `}
`

const statusTag = (status: StatusType) => {
  switch (status) {
    case "ok": {
      return (
        <Tag color="white" bg={getStatusColor(status)} sizing="sm">
          OK
        </Tag>
      )
    }
    case "failed": {
      return (
        <Tag color="white" bg={getStatusColor(status)} sizing="sm">
          Failed
        </Tag>
      )
    }
    case "degradation": {
      return (
        <Tag color="white" bg={getStatusColor(status)} sizing="sm">
          Degradation
        </Tag>
      )
    }
  }
}

const Card = ({
  width,
  containerRect,
  expanded,
  onClick,
  environments,
  name,
  status,
  description,
}: {
  width: number
  containerRect: DOMRect | undefined
  expanded: boolean
  onClick: () => void
  environments: IEnvironment[]
  name: string
  status: StatusType
  description: string
}) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [marginLeft, setMarginLeft] = useState(0)

  useEffect(() => {
    if (ref && ref.current && containerRect) {
      const rect = ref.current.getBoundingClientRect()

      setMarginLeft(-rect.x + containerRect.x)
    }
  }, [containerRect])

  return (
    <StyledCard ref={ref} expanded={expanded}>
      <StyledInner onClick={onClick}>
        <Box fullWidth>
          <Flex alignItems="center" justifyContent="space-between" fullWidth>
            <Text fontSize={7}>{capitalizeFirstLetter(name)}</Text>
            {statusTag(status)}
          </Flex>
        </Box>
      </StyledInner>
      <StyledExpander ml={marginLeft} width={width} key={`expander-${name}`}>
        {expanded && (
          <>
            <Box position="absolute" right={7} top={7}>
              <Icon
                name="close"
                sizing={15}
                color="beta.800"
                hoverColor="beta.600"
                cursor="pointer"
                onClick={onClick}
              />
            </Box>
            <Box py={[8, 9]} px={[8, 10]} width="100%">
              {description && (
                <Box borderRadius={4} py={[3, 5]}>
                  <Text color="beta.700" fontWeight={1} fontSize={7}>
                    {description}
                  </Text>
                </Box>
              )}
              {environments.map(environment => (
                <Environment key={environment.name} environment={environment} />
              ))}
              {environments.length === 0 && (
                <Text fontSize={10} color="beta.400">
                  No data available
                </Text>
              )}
            </Box>
          </>
        )}
      </StyledExpander>
    </StyledCard>
  )
}

const useResize = (myRef: any) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [rect, setRect] = useState<DOMRect | undefined>(undefined)

  useEffect(() => {
    const rect = myRef.current.getBoundingClientRect()
    setRect(rect)
    setWidth(myRef.current.offsetWidth)
    setHeight(myRef.current.offsetHeight)

    const handleResize = () => {
      const rect = myRef.current.getBoundingClientRect()
      setRect(rect)
      setWidth(myRef.current.offsetWidth)
      setHeight(myRef.current.offsetHeight)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [myRef])

  return { width, height, rect }
}

export const CardGrid = memo(({ systems }: { systems: ISystem[] }) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [expandedId, setExpandedId] = useState<string | undefined>(undefined)
  const { width, rect } = useResize(ref)

  return (
    <Grid ref={ref} gridGap={3} gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))">
      {systems.map((system, idx) => {
        const id = idx.toString()
        const onClick = () => (expandedId === id ? setExpandedId(undefined) : setExpandedId(id))

        return (
          <Card
            key={id}
            width={width}
            containerRect={rect}
            expanded={expandedId === id}
            onClick={onClick}
            environments={system.environments}
            name={system.name}
            description={system.description}
            status={system.status}
          />
        )
      })}
    </Grid>
  )
})
