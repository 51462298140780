import React from "react"
import { Flex, Box, Text, FIcon } from "prosapient-styleguide"
import { IEnvironment } from "services/types"
import { History } from "components/Environment/History"
import { getStatusColor } from "components/shared/utils"
import { ServicesTable } from "components/Environment/ServicesTable"

export const Environment = React.memo(({ environment }: { environment: IEnvironment }) => {
  const { history, services, status, env } = environment

  return (
    <Flex flexDirection="column" width="100%" borderRadius={4} border="1px solid" borderColor="beta.200" p={8} mb={10}>
      <Flex alignItems="center" flex={1}>
        <FIcon color={getStatusColor(status)} icon="circle" />
        <Text fontWeight={600} color="beta.700" ml={3}>
          {env}
        </Text>
        <Box flex="1" />
        <Text color={getStatusColor(status)}>{status}</Text>
      </Flex>
      <History history={history} />
      <ServicesTable services={services} />
    </Flex>
  )
})
